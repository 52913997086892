import Image from 'next/image';
import askLogo from '~/public/asksumo.svg';

function AskSumoBox({ summary }: { summary: string }) {
  return (
    <div className="flex flex-col gap-2 rounded-lg bg-asksumo p-4">
      <Image
        className="object-contain"
        src={askLogo}
        alt="AskSumo"
        width={100}
      />

      <p aria-describedby="asksumo-description">{summary}</p>

      <div id="asksumo-description" className="text-sm italic text-grace">
        AI-powered summary of customer reviews
      </div>
    </div>
  );
}

export default AskSumoBox;
