import {
  AlignType,
  Heading,
  MoveType,
  ThumbnailGallery,
  VideoGallery,
} from '@appsumo/dorado-react';
import {
  faExclamationTriangle,
  faSearchPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import Image from 'next/image';
import { useRouter } from 'next/router';
import {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useIntersectionObserver,
  useIsomorphicLayoutEffect,
  useWindowSize,
} from 'usehooks-ts';
import CampaignBanner from '~/components/common/CampaignBanner';
import {
  BToBuy,
  DealBanner,
  DealBreadcrumbs,
  DealComments,
  DealFeatures,
  DealNavigation,
  DealRating,
  Founders,
  PricingCard,
} from '~/components/product';
import { AtAGlance } from '~/components/product/AtAGlance';
import { DealCommentSearch } from '~/components/product/DealCommentSearch';
import { OverviewGuarantee } from '~/components/product/OverviewGuarantee';
import { PricingTable } from '~/components/product/PricingTable';
import { SignUpFooter } from '~/components/product/SignUpFooter';
import { Tldr } from '~/components/product/Tldr';
import { ProductSeo } from '~/components/seo';
import { GlobalContext } from '~/contexts/global';
import { addDealViewActivity } from '~/hooks/activity';
import { useUserStatus } from '~/hooks/userStatus';
import { useMountEffect } from '~/hooks/utils';
import {
  DEAL_COMMENT_TYPE,
  DISCUSSION,
  REVIEW_SORT_OPTIONS,
  getQuestionSortOptions,
} from '~/lib/discussions/constants';
import { DiscussionType } from '~/lib/discussions/types';
import { useEvent, useEventDispatch } from '~/lib/events';
import { useExperiment } from '~/lib/experiment';
import { PDPContext, PDPContextType } from '~/lib/product/context';
import useUser from '~/lib/user';
import { getHashFromPath, sentenceCase } from '~/lib/util';
import {
  LTD_QUANTITY_PDP_EXPERIMENT_NAME,
  DEAL_STAGE,
  SC_ZONE_EXPERIMENT_NAME,
  TERMS_FEATURES_EXPERIMENT_NAME,
  PDP_SKIP_COMMENTS_EXPERIMENT_NAME,
} from '~/lib/util/constants';
import { UserStaffMessageLocations } from '~/constants/product';
import { getViewItemData, gtag } from '~/lib/util/dataLayer';
import { isExperimentalVariant } from '~/lib/util/helpers';
import { Deal, OverviewMedia } from '~/types/deal';
import {
  GalleryImageProp,
  ProductPageProps,
  Section,
  StoryImageProp,
  VideoProp,
} from '~/types/product';

import { SmartCollectionZone } from '~/components/smartcollections/components/SmartCollectionZone';
import { ZoneType } from '~/components/smartcollections/components/types';
import useDealCampaign from '~/hooks/deal-campaign';
import {
  getPlanTypeText,
  isDealInactiveIndefinitely,
  skipQuantityRemaining,
} from '~/lib/product/utils';
import AskSumoBox from '../discussions/AskSumoBox';
import { LTDMessaging } from './PricingCard/LTDMessaging';
import StaffMessage from './StaffMessage';

import DealTerms from '~/components/product/PlansAndFeatures/DealTerms';

// Add the necessary plugins
dayjs.extend(isSameOrAfter);

const DEAL_COPY_DELIMITER = 'START_EXP';
const VARIANT_DELIMITER = 'VARIANT_SPLIT';
const VIDEO_CAROUSEL_COMPONENT_ID = 'deal-videos';

const ProductOverviewMedia = memo(function ProductOverviewMedia({
  alt,
  media,
  slug,
}: {
  alt: string;
  media: OverviewMedia;
  slug: string;
}): JSX.Element {
  const [hasIntersected, setHasIntersected] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});

  if (!!entry?.isIntersecting && !hasIntersected) {
    setHasIntersected(true);
  }

  // look for experimental html_body (goal: test deal copy variants)
  //
  // experiment name will take form: {DEAL_SLUG}-deal-copy
  // variants: a, b, c, ...
  //
  // in deal story > html_body, add 'START_EXP' delimiter to beginning of html_body (make sure to
  // use html editor so you're not accidentally adding extraneous <p> or similar tags)
  //
  // before each add'l variant, add 'VARIANT_SPLIT' delimiter to signify start of new variant
  // here's an example of what html_body might look like:
  //
  // START_EXP<h1> variant 1 </h1>VARIANT_SPLIT<h2> variant 2</h2>
  let variants;
  if (media.html_body?.startsWith(DEAL_COPY_DELIMITER)) {
    variants = media.html_body
      .slice(DEAL_COPY_DELIMITER.length)
      ?.split(VARIANT_DELIMITER);
  }

  const { variant: dealCopyVariant } = useExperiment(
    `${slug}-deal-copy`,
    !variants || !slug,
  );

  let htmlBody = media.html_body;
  if (dealCopyVariant && variants) {
    const variantIndex = dealCopyVariant.charCodeAt(0) - 'a'.charCodeAt(0);

    if (variantIndex >= 0 && variantIndex < variants.length) {
      htmlBody = variants[variantIndex];
    } else {
      // eslint-disable-next-line prefer-destructuring
      htmlBody = variants[0]; // let's give user default in case of issue
    }
  }

  if (htmlBody) {
    return (
      <div
        className="prose"
        dangerouslySetInnerHTML={{
          __html: htmlBody,
        }}
      />
    );
  }

  const renderImage = media.image && !media.embed_code;

  if (media.image || media.embed_code || media.content) {
    return (
      <div className="my-6">
        {!!renderImage && (
          <div
            className="appsumo-story-img-container open-carousel-modal-on-click relative"
            data-story-image-id={media.slug}
          >
            <Image
              src={`${media.image}?width=1280&height=720&aspect_ratio=16:9&optimizer=gif`}
              unoptimized
              alt={media.alt_text || alt}
              width="1280"
              height="720"
              className="appsumo-story-inline-image mb-8 cursor-pointer rounded border border-gray-300"
            />
            <FontAwesomeIcon
              icon={faSearchPlus}
              className="appsumo-hover-mag-glass"
            />
          </div>
        )}
        {!!media.embed_code && !media.image && (
          <div
            ref={ref}
            className="appsumo-story-inline-video"
            dangerouslySetInnerHTML={{
              __html: hasIntersected
                ? media.embed_code
                : media.lazy_embed_code || '',
            }}
          />
        )}
        {(media.image || media.embed_code) && !!media.description && (
          <div className="pt-2 text-center text-sm text-gray-500">
            {media.description}
          </div>
        )}
        {!!media.content && (
          <div
            className="prose mt-6"
            dangerouslySetInnerHTML={{
              __html: media.content,
            }}
          />
        )}
      </div>
    );
  }

  return <></>;
});

const TabButton = forwardRef<HTMLDivElement, any>(
  ({ onClick, onKeyDown, onMouseDown, children, ...props }, ref) => {
    const selected = props['aria-selected'];
    const [buttonClasses, setButtonClasses] = useState<string>('');

    useEffect(() => {
      setButtonClasses(
        clsx(
          selected
            ? "before:content:[''] relative -mb-0.5 border-b-white border-t-blue-600 bg-white before:absolute before:left-0 before:top-0 before:h-[1px] before:w-full before:bg-blue-500"
            : '-mb-1 bg-gray-100',
          '-mb-0.5 border px-7 py-5 font-bold',
        ),
      );
    }, [selected]);

    return (
      <div ref={ref}>
        <button
          role="tab"
          onClick={onClick}
          onMouseDown={onMouseDown}
          onKeyDown={onKeyDown}
          className={buttonClasses}
        >
          {children}
        </button>
      </div>
    );
  },
);

TabButton.displayName = 'TabButton';

const parseImages = (
  deal: any,
  isMobile: boolean = false,
): GalleryImageProp[] => {
  if (!deal) return [];

  let imageList = [];

  imageList.push({
    src: deal.media_url,
    alt: deal.public_name,
  });

  imageList = imageList.concat(
    deal.products?.[0].story?.images?.map((image: StoryImageProp) => ({
      src: image.image,
      alt: image.alt_text,
      slug: image.slug,
    })),
  );

  if (deal.products[0].story.videos && !isMobile) {
    imageList.splice(
      1,
      0,
      ...(deal.products?.[0].story?.videos
        ?.map((video: VideoProp) => {
          if (video.embed_thumbnails?.sm) {
            return {
              src: video.embed_thumbnails.sm,
              alt: video.alt_text,
              embed: video.embed_code,
            };
          }
          return null; // Invalid video, backend could not generate thumbnail
        })
        .filter(Boolean) || []),
    );
  }

  return imageList;
};

/**
 * helper function
 * @param deal
 */
const currentlyBeforeNextStage = (deal: Deal) => {
  const nextStageDate = deal.stage_details?.next_stage_start_date;
  if (!nextStageDate) return false;

  const now = dayjs();
  const diffInMilliseconds = dayjs(nextStageDate).diff(now);

  return diffInMilliseconds > 0;
};

export const ProductPageComponent = ({
  deal,
  questions,
  reviews,
}: ProductPageProps) => {
  const { user } = useUser();
  const { userStatus } = useUserStatus(deal.id);
  const overviewRef = useRef<HTMLElement>(null);
  const galleryRef = useRef(null);
  const [commentQuery, setCommentQuery] = useState<string>('');
  const [questionTotal, setQuestionTotal] = useState<number>(0);
  const [reviewTotal, setReviewTotal] = useState<number>(0);
  const [sections, setSections] = useState<Section[]>([]);
  const [isCommentsSection, setIsCommentsSection] = useState<boolean>(false);
  const [bToBuyHidden, setBToBuyHidden] = useState<boolean>(false);
  const articleRef = useRef<HTMLElement>(null);
  const commentsRef = useRef<HTMLElement>(null);
  const [selectedTab, setSelectedTab] = useState<DiscussionType>(
    DISCUSSION.QUESTIONS,
  );
  const { hasUserDataOnDatalayer, enrolledExperiments, userState } =
    useContext(GlobalContext);
  const { width } = useWindowSize();
  const { dealActiveAutoCampaign, campaignBannerContent, backgroundColor } =
    useDealCampaign(deal);

  const isMobile = useMemo(() => width < 768, [width]);
  const [mobileScreen, setMobileScreen] = useState<boolean>(isMobile);

  const [useV2TermsFeaturesUI, setUseV2TermsFeaturesUI] =
    useState<boolean>(false);
  const { variant: useV2TermsVariant } = useExperiment(
    TERMS_FEATURES_EXPERIMENT_NAME,
  );
  useEffect(() => {
    setUseV2TermsFeaturesUI(isExperimentalVariant(useV2TermsVariant));
  }, [useV2TermsVariant]);

  const { variant: scZoneVariant } = useExperiment(
    SC_ZONE_EXPERIMENT_NAME,
    !deal.buy_button?.is_ended, // only enroll ended/sold out deals
  );

  const shouldSkipQuantityRemaining = useMemo(
    () => skipQuantityRemaining(deal),
    [deal],
  );

  const { variant: ltdQuantityVariant } = useExperiment(
    LTD_QUANTITY_PDP_EXPERIMENT_NAME,
    shouldSkipQuantityRemaining,
  );

  const [showQuantityRemaining, setShowQuantityRemaining] =
    useState<boolean>(false);
  useEffect(() => {
    setShowQuantityRemaining(
      isExperimentalVariant(ltdQuantityVariant) && !shouldSkipQuantityRemaining,
    );
  }, [ltdQuantityVariant, shouldSkipQuantityRemaining]);

  const isEarlyAccessOrLastCall = useMemo(
    () => !!deal.buy_button?.is_early_access || !!deal.buy_button?.is_last_call,
    [deal.buy_button.is_early_access, deal.buy_button.is_last_call],
  );

  const planTypeText = useMemo(
    () => sentenceCase(getPlanTypeText(deal?.unique_plan_types)),
    [deal],
  );

  const product = deal?.products[0];
  const { story } = product;

  const dealDetail = useMemo(() => ({ deal }), [deal]);

  const showCommentType = useCallback(
    (type: DiscussionType) => {
      commentsRef.current?.setAttribute('data-section-id', type);
      setSelectedTab(type);
    },
    [commentsRef],
  );

  const router = useRouter();

  function updateCommentTab() {
    const WHITELISTED_TABS = [
      DISCUSSION.QUESTIONS.toString(),
      DISCUSSION.REVIEWS.toString(),
    ];

    let newHash;
    if (WHITELISTED_TABS.includes(location.hash?.slice(1))) {
      newHash = location.hash.slice(1);
    } else {
      const hashFromPath = getHashFromPath(router?.asPath || '');

      if (WHITELISTED_TABS.includes(hashFromPath)) {
        newHash = hashFromPath;
      }
    }

    if (newHash) {
      setSelectedTab(newHash as DiscussionType);
    }
  }

  // Make sure we have the correct tab selected on page load based on hash (if exists)
  useEffect(() => {
    updateCommentTab();
  }, [router.asPath]); // eslint-disable-line react-hooks/exhaustive-deps -- no need to watch updateCommentTab

  useEffect(() => {
    router.events.on('hashChangeStart', updateCommentTab);

    return () => {
      router.events.off('hashChangeStart', updateCommentTab);
    };
  }, [router]); // eslint-disable-line react-hooks/exhaustive-deps -- no need to watch updateCommentTab

  const imageList = useMemo(
    () => parseImages(deal, mobileScreen),
    [deal, mobileScreen],
  );

  useIsomorphicLayoutEffect(() => {
    const imageEls = overviewRef?.current?.querySelectorAll(
      '.open-carousel-modal-on-click',
    );

    const handleImageClick = (e: MouseEvent): void => {
      const el = e.currentTarget as HTMLElement;

      if (el) {
        const storyImageId = el?.getAttribute('data-story-image-id');

        const index = imageList.findIndex((img) => img.slug === storyImageId);
        if (index > -1) {
          (galleryRef.current as any)?.showImageAt(index);
        }
      }
    };

    imageEls?.forEach((el) =>
      (el as HTMLElement).addEventListener('click', handleImageClick),
    );

    return () => {
      imageEls?.forEach((el) =>
        (el as HTMLElement).removeEventListener('click', handleImageClick),
      );
    };
  }, [overviewRef, galleryRef, imageList]);

  useIsomorphicLayoutEffect(() => {
    const sectionEls = (articleRef?.current as HTMLElement).querySelectorAll(
      'section[id], [data-section-id]',
    );
    const sections: Section[] = [];

    sectionEls.forEach((sectionEl) => {
      const id = sectionEl.getAttribute('id');
      const titleEl = sectionEl.querySelector('h2');
      let title;

      if (id) {
        if (id === VIDEO_CAROUSEL_COMPONENT_ID) {
          title = 'Videos';
        } else if (titleEl) {
          title = titleEl.textContent;
        } else {
          title = sectionEl.textContent;
        }

        if (title) {
          sections.push({
            id,
            elements: [sectionEl],
            name: title,
          });
        }
      } else {
        const sectionId = sectionEl.getAttribute('data-section-id');
        const section = sections.find((s) => s.id === sectionId);

        if (section) {
          section.elements.push(sectionEl);
        }
      }
    });

    setSections(sections);
  }, [articleRef, selectedTab]);

  const handleNavigationChange = (sectionId: string) => {
    if (
      [DISCUSSION.QUESTIONS, DISCUSSION.REVIEWS].includes(
        sectionId as any, // Using any since sectionId might not be a DiscussionType
      )
    ) {
      showCommentType(sectionId as DiscussionType);
      setIsCommentsSection(true);
    }
  };

  // Send view item event to GA4
  useEffect(() => {
    if (!hasUserDataOnDatalayer) return;

    const { deal }: { deal: Deal } = dealDetail;
    const eventData = getViewItemData(deal);

    /**
     * Timeout delays the call and will add the event after the ga4 hook on the layout.
     * This is because effects are called from children to parent and we need to set this
     * after the one on the layout.
     *
     * More info: https://github.com/appsumo/appsumo-next/pull/214#discussion_r1195415137
     **/
    setTimeout(() => {
      gtag('event', 'view_item', eventData);
    });
  }, [hasUserDataOnDatalayer]); // eslint-disable-line react-hooks/exhaustive-deps

  // Set deal comments and reviews total
  useIsomorphicLayoutEffect(() => {
    // if querying for comments, don't set total
    if (commentQuery) return;
    setQuestionTotal(deal?.deal_comment.comment_count || 0);
    setReviewTotal(deal?.deal_review.review_count || 0);
  }, [deal, commentQuery]);

  useMountEffect(() => {
    if (deal?.id) addDealViewActivity({ dealId: deal?.id });
  }, [deal?.id]);

  // We need this, otherwise egjs-flicking throws an error for one of the panels added/removed by us
  //  for not being a valid element
  useEffect(() => {
    if (isMobile !== mobileScreen) {
      setMobileScreen(isMobile);
      (galleryRef.current as any)?.hideModal();
    }
  }, [isMobile, mobileScreen]);

  const showTimer = useMemo(
    () =>
      deal?.active_timer &&
      deal?.stage_details?.current_stage !== DEAL_STAGE.INACTIVE &&
      currentlyBeforeNextStage(deal),
    [deal],
  );

  const isInactiveIndefinitely = useMemo(
    () => isDealInactiveIndefinitely(deal.stage_details),
    [deal.stage_details],
  );

  const memoizedPdpContext: PDPContextType = useMemo(
    () => ({
      ...dealDetail,
      galleryRef,
      showTimer,
      scZoneVariant,
      isPDPPage: true,
      user,
      userStatus,
      showQuantityRemaining,
      isInactiveIndefinitely,
    }),
    [
      galleryRef,
      showTimer,
      dealDetail,
      scZoneVariant,
      user,
      userStatus,
      showQuantityRemaining,
      isInactiveIndefinitely,
    ],
  );

  const [showBToBuy, setShowBToBuy] = useState(
    !deal.buy_button?.is_ended &&
      isCommentsSection &&
      !bToBuyHidden &&
      !(isEarlyAccessOrLastCall && !user?.has_plus),
  );

  useEffect(() => {
    setShowBToBuy(
      !deal.buy_button?.is_ended &&
        isCommentsSection &&
        !bToBuyHidden &&
        !(isEarlyAccessOrLastCall && !user?.has_plus),
    );
  }, [
    deal.buy_button?.is_ended,
    isCommentsSection,
    bToBuyHidden,
    isEarlyAccessOrLastCall,
    user?.has_plus,
  ]);

  const dispatchEvent = useEventDispatch();
  useEffect(() => {
    // user should still be able to click 'b' to buy even if user clicked 'x' to close the button
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (['B', 'b'].includes(event.key) && showBToBuy) {
        dispatchEvent('clicked:bToBuy');
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [dispatchEvent, showBToBuy]);

  useEvent('pdp:toggleBuyButton', (isOpen: boolean) => setBToBuyHidden(isOpen));

  const isPartner = useMemo(() => {
    return deal.partner_user_ids.includes(user?.id || 0);
  }, [deal.partner_user_ids, user?.id]);

  const questionsSortOptions = useMemo(
    () => getQuestionSortOptions({ isPartner }),
    [isPartner],
  );

  // Do not skip the previously viewed comments when clicking "view all" if the user is a staff or moderator
  const isSkipComments =
    !userState?.is_staff &&
    !userStatus?.staff_moderator &&
    !userStatus?.user_moderator &&
    enrolledExperiments?.[PDP_SKIP_COMMENTS_EXPERIMENT_NAME] === 'enabled';

  if (!deal) return <div />;

  return (
    <PDPContext.Provider value={memoizedPdpContext}>
      <div>
        {showBToBuy && <BToBuy />}
        <DealBanner
          banner={deal.banner_details}
          // eslint-disable-next-line tailwindcss/no-custom-classname
          wrapperClassName={clsx(
            'flex px-2 py-1 lg:hidden',
            `bg-${deal.banner_details.bg_color}`,
            deal.banner_details.text_color,
          )}
          showCampaignBanner={false}
          showEndingSoon={true}
          showTimer={showTimer}
        />
        {!!dealActiveAutoCampaign && campaignBannerContent && (
          <CampaignBanner
            bannerContent={campaignBannerContent}
            backgroundColor={backgroundColor}
            bannerBadge={dealActiveAutoCampaign?.campaign_config?.banner_badge}
            fontColor={
              dealActiveAutoCampaign?.campaign_config?.banner_font_color
            }
          />
        )}
        <div className="mx-auto my-2 w-full max-w-7xl grow px-4 md:my-6 md:px-8">
          <StaffMessage
            dealMessage={deal.staff_message}
            hasPurchased={userStatus?.user_has_purchased}
            hasPlus={user?.has_plus}
            className="my-6 lg:hidden"
            location={UserStaffMessageLocations.TOP}
          />
          <DealNavigation
            sections={sections}
            onChange={handleNavigationChange}
            selectedTab={selectedTab}
            setIsCommentsSection={setIsCommentsSection}
          />
          <article ref={articleRef} className="flex flex-col">
            <ProductSeo deal={deal} story={story} />
            {/* Preview message */}
            {deal.is_preview && (
              <div className="mb-4 bg-yellow-100">
                <div className="m-3">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="mr-3 h-4 w-4"
                  />
                  <span>This is a preview of your product detail page.</span>
                </div>
              </div>
            )}
            <SmartCollectionZone zone={ZoneType.PDP_TOP} />
            <header className="lg:hidden">
              <div className="flex flex-wrap gap-2 text-ellipsis">
                <h1 className="grow font-header text-2xl font-bold sm:text-3xl">
                  {deal.public_name}
                </h1>

                {planTypeText === 'Lifetime deal' && !deal?.is_free ? (
                  <LTDMessaging />
                ) : (
                  <div className="my-2 font-header text-sm font-semibold text-grace">
                    {planTypeText}
                  </div>
                )}
              </div>
              <h2 className="mb-1 text-base line-clamp-2">{story.subheader}</h2>
              <DealRating
                averageRating={deal.deal_review.average_rating}
                reviewCount={deal.deal_review.review_count}
                href={`${deal.get_absolute_url}#reviews`}
              />
            </header>
            <DealBreadcrumbs
              deal={deal}
              className="order-6 mb-3 hidden max-lg:flex"
            />
            <main className="flex gap-x-8 gap-y-4 max-lg:flex-col">
              <div className="grow max-lg:order-2">
                <DealBreadcrumbs
                  deal={deal}
                  className="mb-3 flex max-lg:hidden"
                />
                <StaffMessage
                  dealMessage={deal.staff_message}
                  hasPurchased={userStatus?.user_has_purchased}
                  hasPlus={user?.has_plus}
                  className="hidden lg:mb-4 lg:block"
                  location={UserStaffMessageLocations.TOP}
                />
                <section className="max-lg:hidden">
                  <ThumbnailGallery
                    align={AlignType.CENTER}
                    ref={galleryRef}
                    image={{
                      src: `${deal.media_url}?width=800&height=450&aspect_ratio=16:9`,
                      alt: deal.public_name,
                    }}
                    galleryImages={imageList}
                    canZoomMainImage={mobileScreen}
                    className="rounded border border-gray-200 object-cover md:rounded"
                    modalClassName="rounded-none pt-10 md:h-fit md:max-w-[80vw] max-md:max-w-[100vw]"
                    moveType={MoveType.FREE_SCROLL}
                  />
                </section>
                <section className="mt-3">
                  <div
                    className="prose"
                    dangerouslySetInnerHTML={{
                      __html: story.snippet,
                    }}
                  />
                </section>
                <Tldr highlights={story.highlights} />
                <AtAGlance attributes={deal.attributes} />
                <section id="overview" className="py-8" ref={overviewRef}>
                  <h2 className="mb-2 font-header text-3xl font-bold">
                    Overview
                  </h2>
                  {deal.overview_media.map(
                    (media: OverviewMedia, index: number) => (
                      <ProductOverviewMedia
                        key={index}
                        alt={deal.public_name}
                        media={media}
                        slug={deal.slug}
                      />
                    ),
                  )}
                </section>
                <DealFeatures
                  terms={deal.default_plan_terms}
                  commonFeatures={deal.common_features}
                  moneyBackCalloutType={deal.money_back_callout_type}
                  refundableDays={deal.refundable_days}
                  isFree={deal.is_free}
                  useV2TermsFeaturesUI={useV2TermsFeaturesUI}
                />
              </div>
              <aside className="max-lg:order-1">
                <div className="sm:top-0 lg:sticky lg:-mt-20 lg:w-max lg:max-w-sm lg:pt-20">
                  <PricingCard
                    deal={deal}
                    planTypeText={planTypeText}
                    showTimer={showTimer}
                  />
                </div>
              </aside>
            </main>
            <footer className="mt-12">
              <div
                className="appsumo-style-links mx-auto mb-8 max-w-7xl"
                data-section-id="pricePlans"
              >
                <PricingTable
                  plans={deal.plans}
                  isLicensing={deal.use_licensing}
                />
              </div>
              {useV2TermsFeaturesUI && (
                <>
                  <div className="my-6">
                    <OverviewGuarantee
                      calloutType={deal.money_back_callout_type}
                      refundableDays={deal.refundable_days}
                      isFree={deal.is_free}
                      useV2TermsFeaturesUI
                    />
                  </div>
                  <DealTerms terms={deal.default_plan_terms} />
                </>
              )}
              {deal.founders?.posts?.length > 0 && (
                <Founders
                  founders={deal.founders}
                  productUrl={deal.product_url}
                />
              )}
              {!!deal?.video_collection?.videos?.length && (
                <section id={VIDEO_CAROUSEL_COMPONENT_ID}>
                  <div className="mt-10">
                    <Heading.H2 className="mb-4 font-header text-3xl font-bold">
                      {deal?.video_collection?.label ??
                        'See what Sumo-lings are saying'}
                    </Heading.H2>
                    <VideoGallery
                      images={deal?.video_collection?.videos}
                      modalClassName="md:min-w-[700px] lg:min-w-[900px]"
                    />
                  </div>
                </section>
              )}
              <SmartCollectionZone zone={ZoneType.BEFORE_QUESTIONS} />
              <div className="mb-8 max-w-[800px]">
                <StaffMessage
                  dealMessage={deal.staff_message}
                  hasPurchased={userStatus?.user_has_purchased}
                  hasPlus={user?.has_plus}
                  className="-mb-4 mt-6 sm:mt-10 md:mb-2"
                  location={UserStaffMessageLocations.MID}
                />
                <section id="questions" className="text-[0px]">
                  Questions
                </section>
                {!deal.is_info && (
                  <section id="reviews" className="text-[0px]">
                    Reviews
                  </section>
                )}
                <section
                  ref={commentsRef}
                  className="my-8"
                  data-section-id={selectedTab}
                >
                  <h2 className="mb-6 font-header text-3xl font-bold">
                    Questions{deal.is_info ? '' : ' & Reviews'}
                  </h2>
                  <div className="mb-6">
                    <DealCommentSearch
                      query={commentQuery}
                      onChange={(query) => setCommentQuery(query)}
                      label={`Search questions${
                        deal.is_info ? '' : ' & reviews'
                      }`}
                    />
                  </div>
                  <Tab.Group
                    selectedIndex={selectedTab === DISCUSSION.QUESTIONS ? 0 : 1}
                    onChange={(index) =>
                      setSelectedTab(
                        index === 0 ? DISCUSSION.QUESTIONS : DISCUSSION.REVIEWS,
                      )
                    }
                  >
                    <Tab.List className="flex border-b">
                      <Tab as={TabButton}>
                        Questions
                        {questionTotal > 0 && (
                          <small className="ml-1 font-normal">
                            ({questionTotal})
                          </small>
                        )}
                      </Tab>
                      {!deal.is_info && (
                        <Tab as={TabButton}>
                          Reviews
                          {reviewTotal > 0 && (
                            <small className="ml-1 font-normal">
                              ({reviewTotal})
                            </small>
                          )}
                        </Tab>
                      )}
                    </Tab.List>
                    <Tab.Panels>
                      {({ selectedIndex }) => (
                        <>
                          <Tab.Panel
                            className={`${
                              selectedIndex === 0 ? 'flex' : 'hidden'
                            } flex-col gap-y-4 py-4`}
                            static
                          >
                            <DealComments
                              fallbackData={questions}
                              type={DEAL_COMMENT_TYPE.QUESTION}
                              sortOptions={questionsSortOptions}
                              total={questionTotal}
                              updateTotal={setQuestionTotal}
                              query={commentQuery}
                              isSkipComments={isSkipComments}
                            />
                          </Tab.Panel>
                          {!deal.is_info && (
                            <Tab.Panel
                              className={`${
                                selectedIndex === 1 ? 'flex' : 'hidden'
                              } flex-col gap-y-4 py-4`}
                              static
                            >
                              {deal.reviews_summary && (
                                <AskSumoBox summary={deal.reviews_summary} />
                              )}

                              <DealComments
                                fallbackData={reviews}
                                type={DEAL_COMMENT_TYPE.REVIEW}
                                sortOptions={REVIEW_SORT_OPTIONS}
                                total={reviewTotal}
                                updateTotal={setReviewTotal}
                                query={commentQuery}
                                isSkipComments={isSkipComments}
                              />
                            </Tab.Panel>
                          )}
                        </>
                      )}
                    </Tab.Panels>
                  </Tab.Group>
                </section>
              </div>
              <SmartCollectionZone zone={ZoneType.AFTER_QUESTIONS} />
            </footer>
          </article>
        </div>
        <SignUpFooter componentName="pdp-email-footer" />
      </div>
    </PDPContext.Provider>
  );
};
