import { memo, useMemo } from 'react';
import Image from 'next/image';

import { Popover } from '~/components/ui';
import question from '../../public/question-mark.svg';

export type Highlight = {
  highlight: string;
};

export const Tldr = memo(({ highlights }: { highlights: Highlight[] }) => {
  const highlightsToRender = useMemo(
    () =>
      highlights.map((highlight, index) => (
        <div
          key={`${index}-${highlight.highlight.length}`}
          className="border-b border-gray-300 py-2 last:border-none sm:py-4"
        >
          {highlight.highlight}
        </div>
      )),
    [highlights],
  );

  return (
    <section className="mt-10">
      <div className="mb-6 flex items-center gap-x-2">
        <h2 className="font-header text-3xl font-bold">TL;DR</h2>
        <Popover content={`Too long;  Didn't read`}>
          <div className="cursor-pointer">
            <Image src={question} height="24" alt="too long didn't read" />
          </div>
        </Popover>
      </div>
      <div className="flex flex-col rounded bg-gray-100 px-4">
        {highlightsToRender}
      </div>
    </section>
  );
});
Tldr.displayName = 'Tldr';
