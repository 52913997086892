import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faExchangeAlt,
  faTag,
  faLaptopCode,
  faGlobe,
  faList,
  faAward,
  faCogs,
  faCheck,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { memo, useMemo } from 'react';
import { Link } from '~/components/global/Link';

const ATTRIBUTE_BOOLEAN = 'boolean';
const ATTRIBUTE_ENUMERATION = 'enumeration';

type AttributeType = typeof ATTRIBUTE_BOOLEAN | typeof ATTRIBUTE_ENUMERATION;

type Attribute = {
  slug: string;
  name: string;
  type: AttributeType;
  values: {
    slug: string;
    name: string;
  }[];
};

type AttributeIcon = {
  [key: string]: IconDefinition;
};

const ATTRIBUTE_ICONS: AttributeIcon = {
  product_tag: faTag,
  best_for: faStar,
  alternative_to: faExchangeAlt,
  integrations: faLaptopCode,
  os: faGlobe,
  features: faList,
  certification: faAward,
  resource_type: faCogs,
  existing_accounts: faCheck,
};

const BLACKLIST_ATTRIBUTES = [
  'group',
  'category',
  'subcategory',
  'product_type',
  'product_tag',
  'os',
];

const AttributeItem = ({ attribute }: { attribute: Attribute }) => {
  const itemsToRender = useMemo(
    () =>
      attribute.values.map((value) => (
        <li key={value.slug}>
          {attribute.slug === 'certification' ? (
            <span>{value.name}</span>
          ) : (
            <Link
              href={`/collections/${attribute.slug.replace(/_/, '-')}/${
                value.slug
              }`}
              className="cursor-pointer text-blue-500 hover:underline"
            >
              {value.name}
            </Link>
          )}
        </li>
      )),
    [attribute],
  );

  const icon: IconDefinition = ATTRIBUTE_ICONS[attribute.slug] || faStar;

  return (
    <div className="mb-2 flex gap-x-4">
      <FontAwesomeIcon icon={icon} className="text-xl leading-none" />
      <div>
        <h3 className="text-base font-semibold leading-4">{attribute.name}</h3>
        {attribute.values.length > 0 && (
          <ul className="mt-2 list-inside list-disc">{itemsToRender}</ul>
        )}
      </div>
    </div>
  );
};

export const AtAGlance = memo(function AtAGlance({
  attributes,
}: {
  attributes: Attribute[];
}) {
  // filters out blacklisted attributes and boolean attributes that are false
  const attributesToRender = useMemo(
    () =>
      attributes
        .filter((attribute) => !BLACKLIST_ATTRIBUTES.includes(attribute.slug))
        .filter(
          (attribute) =>
            !(attribute.type === ATTRIBUTE_BOOLEAN && !attribute.values),
        )
        .map((attribute) => {
          if (attribute.type === ATTRIBUTE_BOOLEAN) {
            attribute.values = [];
          }

          return <AttributeItem key={attribute.slug} attribute={attribute} />;
        }),
    [attributes],
  );

  if (!attributesToRender.length) return null;

  return (
    <section className="mt-10">
      <h2 className="mb-6 font-header text-3xl font-bold">At-a-glance</h2>
      <div className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-2">
        {attributesToRender}
      </div>
    </section>
  );
});
