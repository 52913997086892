import { memo, useContext, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Dropdown,
  DropdownOptionProps,
  DropdownProps,
} from '@appsumo/dorado-react';
import { currency } from '~/lib/format';
import { useCart } from '~/lib/cart';
import { useEvent, useEventDispatch } from '~/lib/events';
import { COMING_SOON_BANNER_TEXT } from '~/lib/campaigns/constants';
import { PDPContext } from '~/lib/product/context';
import type { Deal } from '~/types/deal';
import type { PlanProps } from '~/types/product';
import useUser from '~/lib/user';
import { LineItemAddFunction } from '~/lib/cart/useCart';

interface PricingCardProps {
  isLicensing: boolean;
  plan: PlanProps;
  extraPlans?: PlanProps[] | undefined;
  onChange: (planId: number) => void;
  forceDropdownHeight?: boolean;
  hasHeader?: boolean;
}

const PricingTableBuyButton = memo(function PricingTableBuyButton({
  deal,
  dealInactive,
  plan,
  isLicensing,
  lineItemAdd,
  dispatchEvent,
}: {
  deal: Deal;
  dealInactive: boolean;
  plan: PlanProps;
  isLicensing: boolean;
  lineItemAdd: LineItemAddFunction;
  dispatchEvent: (event: string, data?: any) => void;
}) {
  const { user } = useUser();

  if (dealInactive) return <></>;

  const isEarlyAccessOrLastCall =
    deal.buy_button.is_early_access || deal.buy_button.is_last_call;

  const onBuyClick = () => {
    lineItemAdd(deal.id, plan.id, '', isLicensing ? 1 : plan.codes, false);
  };

  if (isEarlyAccessOrLastCall) {
    const plusBuyText = `${
      plan.is_free ? 'Get for FREE' : 'Buy now'
    } with AppSumo Plus`;

    return user?.has_plus ? (
      <Button className="w-full rounded-full" onClick={onBuyClick}>
        {plusBuyText}
      </Button>
    ) : (
      <Button
        className="block w-full rounded-full text-center"
        onClick={() => dispatchEvent('pdp:showPlusShelf')}
      >
        {plusBuyText}
      </Button>
    );
  } else if (
    deal.banner_details?.text === COMING_SOON_BANNER_TEXT ||
    !!deal.buy_button?.is_coming_soon
  ) {
    return (
      <Button className="w-full rounded-full text-center" disabled>
        Coming soon
      </Button>
    );
  }

  let buyText;
  if (plan.is_free) {
    buyText = 'Get now for FREE';
  } else {
    buyText = 'Buy now';

    if (!isLicensing && !!plan?.codes) {
      buyText += ` (${plan?.codes} Codes)`;
    }
  }

  return (
    <Button className="w-full rounded-full" onClick={onBuyClick}>
      {buyText}
    </Button>
  );
});

export const PricingCard = memo(function PricingCard({
  plan,
  extraPlans,
  onChange,
  isLicensing,
  forceDropdownHeight,
}: PricingCardProps) {
  const router = useRouter();
  const { deal, isInactiveIndefinitely } = useContext(PDPContext);
  const { lineItemAdd } = useCart();
  const dispatchEvent = useEventDispatch();

  useEvent('cart:added', () => {
    router.push('/cart');
  });

  const firstPlanTier = deal?.plans?.sort((a, b) => a.tier - b.tier)?.[0]?.tier;

  const features = useMemo(
    () =>
      plan.plan_features.map((feature) => {
        const index = firstPlanTier === 0 ? plan.tier : plan.tier - 1;

        if (
          feature.code_to_feature_conf &&
          index < feature.code_to_feature_conf.length
        ) {
          const { quantity } = feature.code_to_feature_conf[index];

          return {
            id: feature.id,
            text: feature.feature.replace(
              '{{}}',
              `<b>${quantity || '&nbsp'}</b>`,
            ),
          };
        }
        return {
          id: feature.id,
          text: feature.feature,
        };
      }),
    [plan, firstPlanTier],
  );

  const options: DropdownOptionProps[] = useMemo(
    () =>
      extraPlans?.map((plan) => ({
        label: isLicensing ? plan?.public_name : `${plan?.codes} Codes`,
        value: plan.id,
      })) ?? [],
    [extraPlans, isLicensing],
  );

  const pricingTableDropdownRef = useRef<DropdownProps>(null);

  // user selected plan from pricing card dropdown, so let's update pricing table selection here (if possible)
  useEvent('plan:updatePricingTable', (newPlan) => {
    // pricing card options have different text values so we have to do this
    const matchingPlan = options?.find(
      (option: DropdownOptionProps) => option?.value === newPlan?.value,
    );
    if (matchingPlan && !!pricingTableDropdownRef?.current?.setValue) {
      pricingTableDropdownRef.current.setValue(matchingPlan); // update dropdown UI
      onChange(parseInt(String(matchingPlan.value), 10)); // sets planId for pricing
    }
  });

  return (
    <div
      className={clsx(
        'relative flex flex-col rounded-b border border-sundog bg-white px-4 pt-4 shadow-pricing-card',
        { 'h-full rounded-t': !plan.highlight },
      )}
    >
      <div className="grow">
        <div className="px-4 pb-3 text-center">
          <p className="text-xl font-medium text-black-pearl">
            {plan.public_name}
          </p>
        </div>

        {!isInactiveIndefinitely && (
          <div
            className={clsx('flex flex-col text-center', {
              'pb-4': forceDropdownHeight,
            })}
          >
            <p>
              {plan.plan_desc.replace(
                'One Time Purchase of',
                'One-time purchase of',
              )}
            </p>
            <div className="flex items-center justify-center">
              <strong className="text-2xl font-medium text-black-pearl">
                {currency(parseFloat(plan.price))}
              </strong>
              <span className="ml-2 text-grace line-through">
                {currency(parseFloat(plan.original_price))}
              </span>
            </div>
          </div>
        )}

        <div className={clsx({ 'md:min-h-[42px]': forceDropdownHeight })}>
          {options && options.length > 0 && (
            <Dropdown
              ref={pricingTableDropdownRef}
              options={options}
              defaultValue={{
                value: plan.id,
                label: isLicensing ? plan.public_name : `${plan?.codes} Codes`,
              }}
              onClick={(option) => {
                onChange(option.value as number);
                dispatchEvent('plan:updatePricingCard', option);
              }}
            />
          )}
        </div>

        <div className="py-4">
          <PricingTableBuyButton
            deal={deal}
            dealInactive={!!isInactiveIndefinitely}
            plan={plan}
            isLicensing={isLicensing}
            lineItemAdd={lineItemAdd}
            dispatchEvent={dispatchEvent}
          />
        </div>

        <div className="px-2 pb-6">
          <ul className="flex flex-col gap-y-2">
            {(deal.plans ?? []).length > 1 && (
              <li className="flex gap-x-2 leading-6">
                <FontAwesomeIcon icon={faCheck} className="mt-1 text-dollar" />
                <span>
                  <b>All features above included</b>
                </span>
              </li>
            )}
            {features.map((feature) => (
              <li key={feature.id} className="flex gap-x-2 leading-6">
                <FontAwesomeIcon icon={faCheck} className="mt-1 text-dollar" />
                <span dangerouslySetInnerHTML={{ __html: feature.text }} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
});
