import { memo, useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';

import type { PlanProps } from '~/types/product';
import { PricingCard } from './PricingCard';

interface PricingTableProps {
  isLicensing: boolean;
  plans: PlanProps[];
}

export const PricingTable = memo(function PricingTable({
  plans,
  isLicensing,
}: PricingTableProps) {
  const [lastPlan, setLastPlan] = useState<number>(2);
  const showPlans = useMemo(
    () => [...plans.slice(0, 2)].concat(plans[lastPlan]).filter(Boolean),
    [plans, lastPlan],
  );
  const extraPlans = useMemo(() => plans.slice(2), [plans]);

  const onChangePlan = useCallback(
    (planId: number) => {
      setLastPlan(plans.findIndex((plan) => plan.id === planId));
    },
    [plans],
  );

  const hasRecommendedPlan = showPlans.some((plan) => !!plan.highlight);

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
      {showPlans.map((plan, index) => (
        <div key={plan.id}>
          <div
            className={clsx(
              'h-full',
              { 'md:pt-9': hasRecommendedPlan && !plan.highlight }, // there is a plan highlight but not this card
            )}
          >
            {!!plan.highlight && (
              <div className="rounded-t bg-spruce px-6 py-2 text-center text-sm text-white">
                Recommended
              </div>
            )}
            <PricingCard
              plan={plan}
              isLicensing={isLicensing}
              extraPlans={
                index === 2 && extraPlans.length > 1 ? extraPlans : undefined
              }
              onChange={onChangePlan}
              forceDropdownHeight={extraPlans?.length > 1}
            />
          </div>
        </div>
      ))}
    </div>
  );
});
