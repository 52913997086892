import React, { memo, useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import Image from 'next/image';
import { Button, Heading } from '@appsumo/dorado-react';

import { UserStaffMessageGroupTypes } from '~/constants/product';
import { StaffMessageProps } from '~/types/product';

import UpsellExposureIcon from '~/public/exposure-icon.png';
import SumoBadge from '~/public/sumo-badge.png';
import ChevronDown from '~/public/font-awesome/chevron-down-midnight.png';

import useUser from '~/lib/user';

const AVATAR_IMAGE_SIZE = 64;
const EXPOSURE_ICON_SIZE = 56;
const CHEVRON_ICON_SIZE = 16;

export default memo(function StaffMessage({
  className,
  dealMessage,
  location,
  hasPurchased = false,
  hasPlus = false,
}: StaffMessageProps): React.ReactElement | null {
  const { user } = useUser();
  const [isExpanded, setIsExpanded] = useState(false);
  const userGroup = dealMessage?.user_group;
  const iconClassName = clsx(
    'text-right transition duration-100 ease-in-out',
    isExpanded && 'rotate-180',
  );

  // Deal data (incl. message) is loaded before user data (SSG)
  // Quickly check a few message conditions to determine if we should display it
  // Bulk of validation is in the backend (including date)
  const canDisplayMessage =
    dealMessage?.title &&
    dealMessage?.author?.name &&
    dealMessage.location === location;

  if (!canDisplayMessage) return null;

  // Check if the user can see the message based on their userStatus
  const canUserSeeMessage =
    user?.is_staff ||
    userGroup === UserStaffMessageGroupTypes.ALL ||
    (userGroup === UserStaffMessageGroupTypes.PAID && hasPurchased) ||
    (userGroup === UserStaffMessageGroupTypes.PAID_PLUS &&
      hasPurchased &&
      hasPlus) ||
    (userGroup === UserStaffMessageGroupTypes.PAID_NON_PLUS &&
      hasPurchased &&
      !hasPlus);

  if (!canUserSeeMessage) return null;

  return (
    <div
      className={clsx(
        className,
        'bg-dollar/10 p-4',
        isExpanded ? 'md:p-6 md:pt-2' : 'md:px-6 md:py-2',
      )}
    >
      {/* use button for accessibility*/}
      <button
        className="flex w-full items-center"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Image
          src={UpsellExposureIcon}
          alt="offer icon"
          width={EXPOSURE_ICON_SIZE}
          height={EXPOSURE_ICON_SIZE}
          className="hidden md:block"
        />
        <span
          data-testid="message-title"
          className="block grow text-left font-header text-base font-semibold md:text-xl"
        >
          {dealMessage.title}
        </span>
        <Image
          src={ChevronDown}
          alt="chevron"
          width={CHEVRON_ICON_SIZE}
          height={CHEVRON_ICON_SIZE}
          className={iconClassName}
        />
      </button>
      {isExpanded && (
        <>
          <p className="mt-2 text-sm text-grace">
            Posted {dayjs(dealMessage.start_date).format('MMM DD, YYYY')}
          </p>
          <div
            className="mt-2 text-base"
            dangerouslySetInnerHTML={{
              __html: dealMessage.message,
            }}
          />
          <div className="mt-6 md:flex md:items-center">
            <div className="flex items-center">
              <Image
                src={dealMessage.author.avatar}
                className="mr-[18px] h-16 w-16 rounded-full"
                alt="avatar"
                unoptimized
                width={AVATAR_IMAGE_SIZE}
                height={AVATAR_IMAGE_SIZE}
              />
              <div>
                <div className="flex items-center">
                  <Heading.H5
                    className="mr-2"
                    data-testid="message-author-name"
                  >
                    {dealMessage.author.name}
                  </Heading.H5>
                  <Image
                    src={SumoBadge}
                    alt="sumo badge"
                    width={42}
                    height={18}
                    className="h-[18px] w-[42px]"
                  />
                </div>
                <p>{dealMessage.author.title}</p>
              </div>
            </div>
            {dealMessage.cta_text && dealMessage.cta_url ? (
              <a
                href={dealMessage.cta_url}
                target="_blank"
                rel="noreferrer"
                className="grow md:text-right"
                data-testid="message-cta"
              >
                <Button secondary className="mt-6 w-full md:mt-0 md:w-auto">
                  {dealMessage.cta_text}
                </Button>
              </a>
            ) : (
              <div className="grow" />
            )}
          </div>
        </>
      )}
    </div>
  );
});
