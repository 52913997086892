import React, { memo } from 'react';
import clsx from 'clsx';
import { FieldValues } from 'react-hook-form';
import { Button, Form } from '@appsumo/dorado-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import { useEmailSubscribe } from '~/lib/util/hooks';
import { UseEmailSubscribeProps } from '~/lib/util/hooks/emailSubscribe';
import { emailValidationSchema } from '~/schemas/email';
import useUser from '~/lib/user';
import { Loader } from '~/components/ui';

interface ComponentNameProps {
  componentName?: string;
  submitText?: string;
  className?: string;
  buttonClassName?: string;
}

export const SignUpFooter = memo(function SignUpFooter({
  componentName = 'sign-up-footer',
  submitText = 'Submit',
  className,
}: ComponentNameProps): React.ReactElement | null {
  const emailSubscribeProps: UseEmailSubscribeProps = {
    customFormData: { component: componentName },
  };

  const {
    createUser,
    hasEmailError,
    isEmailSubmitted,
    isEmailValid,
    isProcessing,
    onFormChangeEmail,
  } = useEmailSubscribe(emailSubscribeProps);
  const { user } = useUser();

  const watch = (data: FieldValues) => {
    onFormChangeEmail(data);
  };

  const emailErrorMessage =
    hasEmailError || 'A valid email address is required.';
  const errorMessage = !isEmailValid || hasEmailError ? emailErrorMessage : '';

  const inputClassName = clsx(
    'mb-0 h-11 min-w-full rounded-[4px] border-r-0 px-4 py-2 outline-none md:min-w-[350px] md:rounded-r-none',
    {
      'border border-gravel text-gravel': isProcessing,
      'border border-slate text-midnight focus:border-dorado focus:border-2':
        !isProcessing && !errorMessage,
      'border-2 border-brick text-brick': errorMessage && !isProcessing,
    },
  );
  const buttonClassName = clsx(
    'mt-2 h-11 w-full whitespace-nowrap rounded leading-none md:mt-0 md:w-28 md:rounded-l-none',
    errorMessage && 'md:border-l-0 md:border-brick',
  );

  // Do not render for existing users
  // We still want to show the email footer success state once the user submits and is logged in
  if (user?.id && !isEmailSubmitted) return null;

  const footerContents = isEmailSubmitted ? (
    <div className="mt-4 flex items-center rounded bg-dollar px-4 py-3 text-white ">
      <FontAwesomeIcon
        icon={faCircleCheck}
        height="18"
        className="mr-3 text-lg"
      />
      <span>
        <b>Success!</b> You are now signed up as <b>{user?.username}</b>. Check
        your email to verify your account.
      </span>
    </div>
  ) : (
    <Form
      onSubmit={() => createUser(componentName)}
      resolver={yupResolver(emailValidationSchema)}
      watch={watch}
    >
      <div className={className}>
        <div className="group md:flex">
          <div className="mt-4 w-full max-w-2xl">
            <Form.Input
              name="email"
              error={errorMessage}
              placeholder="Enter your email here..."
              label="Email"
              labelClasses="text-white font-semibold font-header"
              inputClasses={inputClassName}
              disabled={isProcessing}
            />
          </div>
          <div className="md:mt-12">
            <Button
              type="submit"
              disabled={!!errorMessage || isProcessing}
              className={buttonClassName}
            >
              {isProcessing ? <Loader size="sm" /> : submitText}
            </Button>
            <div className={errorMessage ? 'mb-7' : ''} />
          </div>
        </div>
      </div>
    </Form>
  );

  return (
    <div className="flex flex-col items-center justify-center bg-black-pearl p-8 md:p-12">
      <div className="max-w-[530px]">
        <h4 className="text-center text-3xl font-bold text-dorado">Sign up</h4>
        <p className="mt-4 text-center text-white">
          All our deals are time-sensitive! Make sure you don&apos;t miss any of
          our awesome limited-time offers.
        </p>
        {footerContents}
      </div>
    </div>
  );
});
