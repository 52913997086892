import React, { ReactElement } from 'react';
import Image from 'next/image';
import { Heading } from '@appsumo/dorado-react';

import CircleNotch from '~/public/font-awesome/circle-notch-bolt.png';
import { CommonFeature } from '~/types/deal';

interface DealTermsProps {
  terms: CommonFeature[];
}

export default React.memo(function DealTerms({
  terms,
}: Readonly<DealTermsProps>): ReactElement {
  return (
    <div className="rounded bg-iceberg p-4">
      <Heading.H4 className="mb-4">Deal terms and conditions</Heading.H4>
      <ul className="appsumo-style-links flex flex-col gap-y-4">
        {terms.map((term) => (
          <li key={term.id} className="flex items-center gap-x-3 leading-6">
            <Image
              src={CircleNotch}
              alt="circle-notch"
              width={20}
              height={20}
            />
            <span dangerouslySetInnerHTML={{ __html: term.text }} />
          </li>
        ))}
      </ul>
    </div>
  );
});
